var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("validation-observer", {
        ref: "form",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "v-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return handleSubmit(_vm.onSubmit)
                      },
                    },
                  },
                  [
                    _c(
                      "v-card",
                      { attrs: { outlined: "" } },
                      [
                        _vm.portfolioId
                          ? _c("v-card-title", [
                              _vm._v("Editing " + _vm._s(_vm.name)),
                            ])
                          : _c("v-card-title", [
                              _vm._v("Creating new portfolio"),
                            ]),
                        _c(
                          "v-card-text",
                          [
                            _c("v-text-field", {
                              attrs: { label: _vm.$t("name") },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name = $$v
                                },
                                expression: "name",
                              },
                            }),
                            _c("v-select", {
                              attrs: {
                                items: [
                                  "EUR",
                                  "AUD",
                                  "TRY",
                                  "SEK",
                                  "INR",
                                  "JPY",
                                  "KRW",
                                  "USD",
                                  "NOK",
                                  "CNY",
                                  "CAD",
                                  "THB",
                                  "GBP",
                                  "HKD",
                                  "TWD",
                                  "ZAR",
                                  "IDR",
                                  "BRL",
                                  "PLN",
                                  "SGD",
                                  "ILS",
                                  "QAR",
                                  "SAR",
                                  "DKK",
                                  "CLP",
                                  "MXN",
                                  "CHF",
                                  "NZD",
                                  "MYR",
                                  "ISK",
                                  "ARS",
                                  "CZK",
                                  "GBP",
                                  "HUF",
                                  "AED",
                                  "KWD",
                                  "TND",
                                ],
                                label: "Devise",
                              },
                              on: { change: _vm.updateCurrencySymbol },
                              model: {
                                value: _vm.currency,
                                callback: function ($$v) {
                                  _vm.currency = $$v
                                },
                                expression: "currency",
                              },
                            }),
                            _c("v-text-field", {
                              attrs: {
                                label: _vm.$t("budget"),
                                prefix: _vm.currencySymbol,
                              },
                              on: {
                                keydown: function ($event) {
                                  return _vm.restrictInput($event, "budget")
                                },
                              },
                              model: {
                                value: _vm.budget,
                                callback: function ($$v) {
                                  _vm.budget = $$v
                                },
                                expression: "budget",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("upload-error", { attrs: { error: _vm.error } }),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              { attrs: { type: "submit", color: "primary" } },
                              [_vm._v(_vm._s(_vm.$t("actions.save")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }