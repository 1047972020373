<template>
  <div>
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <v-card outlined>
          <v-card-title v-if="portfolioId">Editing {{ name }}</v-card-title>
          <v-card-title v-else>Creating new portfolio</v-card-title>
          <v-card-text>
            <v-text-field v-model="name" :label="$t('name')" />

            <v-select v-model="currency" :items="[
              'EUR', 'AUD', 'TRY', 'SEK', 'INR', 'JPY', 'KRW', 'USD', 'NOK', 'CNY',
              'CAD', 'THB', 'GBP', 'HKD', 'TWD', 'ZAR', 'IDR', 'BRL', 'PLN', 'SGD',
              'ILS', 'QAR', 'SAR', 'DKK', 'CLP', 'MXN', 'CHF', 'NZD', 'MYR', 'ISK',
              'ARS', 'CZK', 'GBP', 'HUF', 'AED', 'KWD', 'TND'
            ]" label="Devise" @change="updateCurrencySymbol"></v-select>
            <v-text-field v-model="budget" :label="$t('budget')" @keydown="restrictInput($event, 'budget')"
              :prefix="currencySymbol" />

          </v-card-text>
          <upload-error :error="error" />
          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" color="primary">{{ $t('actions.save') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

    </validation-observer>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import formatter from "@/mixins/formatter";

@Component({ mixins: [formatter] })
export default class PortfolioForm extends Vue {

  @Prop() portfolioId;


  name = null;
  currency = null
  budget = null
  budgetPrefix = null
  error = null;
  currencySymbol = null
  mounted() {
    // get or create portfolio object

    if (this.portfolioId) {
      this.$api.get(`portfolio/${this.portfolioId}`).then(j => {
        console.log(j.data)
        this.portfolio = j.data.id;
        this.name = j.data.name;
        this.currency = j.data.devise;
        this.budget = j.data.budget;

      });
    } else {
      this.name = ""
      this.currency = ""
      this.budget = ""
    }

  }
  @Watch("portfolio")
  onCurrencyChange() {
    this.updateCurrencySymbol();
  }

  onSubmit() {
    let id = parseInt(this.portfolioId) || 0;

    const call = this.$api.post(`portfolio/add-edit`, {
      id: this.portfolioId,
      name: this.name,
      devise: this.currency,
      budget: this.budget

    });

    call.then(j => {
      this.$emit('save', j.data);
    }).catch(err => {
      if (err.response) this.error = err.response.data;
      //  this.$snack.validationError(err.response.data)
    });
  }
  restrictInput(event, attributeName) {
    // Allow: backspace, delete, tab, escape, enter
    const allowedKeys = [
      'Backspace',
      'Delete',
      'Tab',
      'Escape',
      'Enter',

    ];
    const currentAttribute = this[attributeName];
    // Check for allowed keys
    if (allowedKeys.includes(event.key) || (event.ctrlKey && allowedKeys.includes(event.key))) {
      return; // Allow these keys
    }
    // Allow only digits and a decimal point
    const isDigit = event.key >= '0' && event.key <= '9';
    const isDecimalPoint = event.key === '.';

    // Prevent the first character from being anything other than a digit (not including decimal point)
    if (currentAttribute && currentAttribute.length === 0 && !isDigit && isDecimalPoint) {
      event.preventDefault(); // Block if the first character is not a digit
      return;
    }
    if (!currentAttribute && isDecimalPoint) {
      event.preventDefault(); // Block if the first character is not a digit
      return;
    }
    // Prevent multiple decimal points
    if (isDecimalPoint && currentAttribute.includes('.')) {
      event.preventDefault(); // Block if another decimal point is already present
      return;
    }

    // Block any invalid keys (anything other than digits and a decimal point)
    if (!isDigit && !isDecimalPoint) {
      event.preventDefault(); // Block invalid characters
    }
  }

  updateCurrencySymbol() {
    this.currencySymbol = this.getCurrencySymbol(this.currency);
  }
  getCurrencySymbol(currency) {
    const currencySymbols = {
      'EUR': '€',
      'AUD': 'A$', // Australian Dollar symbol
      'TRY': '₺',
      'SEK': 'kr',
      'INR': '₹',
      'JPY': '¥',
      'KRW': '₩',
      'USD': '$',
      'NOK': 'kr',
      'CNY': '¥',
      'CAD': 'CA$',
      'THB': '฿',
      'GBP': '£',
      'HKD': 'HK$',
      'TWD': 'NT$',
      'ZAR': 'R',
      'IDR': 'Rp',
      'BRL': 'R$',
      'PLN': 'zł',
      'SGD': 'S$',
      'ILS': '₪',
      'QAR': '﷼',
      'SAR': '﷼',
      'DKK': 'kr',
      'CLP': 'CLP$',
      'MXN': 'MX$',
      'CHF': 'CHF',
      'NZD': 'NZ$',
      'MYR': 'RM',
      'ISK': 'kr',
      'ARS': '$',
      'CZK': 'Kč',
      'HUF': 'Ft',
      'AED': 'د.إ',
      'KWD': 'د.ك',
      'TND': 'د.ت'
    };

    // Return the symbol for the selected currency
    return currencySymbols[currency] || currency; // Fallback to currency code if symbol is not found

  }

  get rules() {
    return this.$store.state.rules;
  }

}
</script>

<style>
#fileInput {
  display: none;
}

h2 {
  margin: 1em 0;
}
</style>
